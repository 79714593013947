import * as ko from 'knockout';

export class ConfirmClick implements KnockoutBindingHandler<Element, any, any> {


    init?(element: Element, valueAccessor: any, allBindings: any, viewModel: any) {
        {
            var value = valueAccessor();
            var message = ko.unwrap(value.message);
            var click = value.click;
            ko.applyBindingsToNode(element, {
                click: (x) => {
                    if (confirm(message))
                        return click.apply(this, Array.prototype.slice.apply(x));
                }
            }, viewModel);
        }
    }

}

export interface IConfirmClickKnockoutBindingHandlers extends KnockoutBindingHandlers {
    confirmClick: KnockoutBindingHandler;
}