import * as ko from 'knockout';
import { CustomerType } from "../types/Enums";
import {
    MyPageModel, PersonFormConfiguration, OrganizationAdminPageModel,
    AdminPageInitialModel, OrganizationTabModel, KeyValuePair, OrganizationRequestModel, ReturnModel, Guid,
    AdminPageReturnModel, ReturnRowModel, ReturnReason, UpdateReturnRequest
} from "../types/Models";
import { ITranslationService } from "../common/TranslationService";
import { MessageHandler, IMessageHandler } from "../common/MessageHandler";
import { Scroller } from "../common/Scroller";
import { IMyPageServiceProxy } from "./MyPageServiceProxy";
import { IVatServiceProxy } from "./VatServiceProxy";
import { IPostalCodeServiceProxy } from "./PostalCodeServiceProxy";
import { AccountDetailsViewModel } from "./AccountDetailsViewModel";
import { MyAddressesViewModel } from "./MyAddressesViewModel";
import { OrderHistoryViewModel } from "./OrderHistoryViewModel";
import { CompanyDetailsViewModel } from "./CompanyDetailsViewModel";
import { PersonsViewModel } from "./PersonsViewModel";
import { CustomerSupportViewModel } from "./CustomerSupportViewModel";
import { ViewModel } from "./ViewModel";
import { IEventAggregator } from "../common/EventAggregator";
import { VatNumberValidator } from "./VatNumberValidator";
import { PostalCodeValidator } from "./PostalCodeValidator";
import { GlobalEvents } from "../common/GlobalEvents";
import { ApplyInvoiceViewModel } from "./ApplyInvoiceViewModel";
import {
    IValidatableObservable,
    RequiredValidator,
    EqualsValidator,
    FormValidator,
    MinLengthValidator
} from "../common/Validation";

import Promise from "ts-promise";
import { CheckVatResponse } from "../types/Models";
import { AjaxRequestSender } from "../common/ajax/AjaxRequestSender";
import { Enumerable } from "../common/Enumerable";


export class AdminPageViewModel {


    errorHandler: MessageHandler;
    successHandler: MessageHandler;

    adminPageServiceProxy: IAdminPageServiceProxy;
    organizationTab: OrganizationTabViewModel;
    returnsTab: ReturnManagementTabViewModel;
    isLoading: KnockoutObservable<boolean>;
    eventAggregator: IEventAggregator;
    constructor(initModel: AdminPageInitialModel, eventAggregator: IEventAggregator, service: IAdminPageServiceProxy) {
        this.adminPageServiceProxy = service;
        this.eventAggregator = eventAggregator;
        this.errorHandler = new MessageHandler();
        this.successHandler = new MessageHandler();
        this.isLoading = ko.observable(false);

        this.organizationTab = new OrganizationTabViewModel(this.isLoading, service, this.errorHandler, this.successHandler);
        this.returnsTab = new ReturnManagementTabViewModel(initModel.returnReasons, eventAggregator,  this.isLoading, service, this.errorHandler, this.successHandler);
        this.organizationTab.isActive(true);
    }


    showOrganizationTab() {
        this.showViewModel(this.organizationTab);
    }

    showReturnsTab() {
        this.showViewModel(this.returnsTab);
    }


    private showViewModel(viewModel: ViewModel) {
        this.deactivateAll();
        viewModel.isActive(true);
    }

    private deactivateAll() {
        this.organizationTab.isActive(false);
        this.returnsTab.isActive(false);
    }
    toggleOrganizationTab() {
        this.toggleViewModel(this.organizationTab);
    }
    toggleReturnTab() {
        this.toggleViewModel(this.returnsTab);
    }
    private toggleViewModel(viewModel: ViewModel) {
        viewModel.isActive(!viewModel.isActive());
    }
}


export class TabViewModel extends ViewModel {

    public searchField: IValidatableObservable<string>;
    public isLoading: KnockoutObservable<boolean>;
    dataLoaded: KnockoutObservable<boolean> = ko.observable(false);
    errorHandler: MessageHandler;
    successHandler: MessageHandler;
}
export enum AdminPageEvent {
    UpdateReturn = "AdminPageUpdateReturn",
    CancelReturn = "AdminPageCancelReturn",
    Error = "AdminPageError"
}

export class ReturnManagementTabViewModel extends TabViewModel {
   

    adminPageServiceProxy: IReturnManagementServiceProxy;
    returnViewModel: OrderReturnsViewMode;
    eventAggregator: IEventAggregator;
    constructor(returnReasons: ReturnReason[], eventAggregator: IEventAggregator  ,isLoading: KnockoutObservable<boolean>,
        service: IReturnManagementServiceProxy,
        errorHandler: MessageHandler,
        successHandler: MessageHandler) {
        super();
        this.errorHandler = errorHandler;
        this.successHandler = successHandler;
        this.adminPageServiceProxy = service;
        this.dataLoaded = ko.observable(false);
        this.searchField = <IValidatableObservable<string>>ko.observable("").extend({});
        this.isLoading = isLoading;
        this.eventAggregator = eventAggregator;

        this.returnViewModel = new OrderReturnsViewMode(returnReasons, eventAggregator);

        this.eventAggregator.subscribe(AdminPageEvent.UpdateReturn, (model) => {
            this.isLoading(true);
            this.dataLoaded(false);
            this.errorHandler.clear();
            this.successHandler.clear();

            this.adminPageServiceProxy.updateOrderReturn(model).then((result) => {
              
                this.populateReturns(result);
                this.dataLoaded(true);

            }).catch((e) => {

                this.errorHandler.handle(e.message);

            }).finally(() => {
                this.isLoading(false);
            });


        }, this);

        this.eventAggregator.subscribe(AdminPageEvent.CancelReturn, (model) => {
            this.isLoading(true);
            this.dataLoaded(false);
            this.errorHandler.clear();
            this.successHandler.clear();

            this.adminPageServiceProxy.cancelReturn(model.id, model.returnId).then((result) => {

                this.populateReturns(result);
                this.dataLoaded(true);

            }).catch((e) => {

                this.errorHandler.handle(e.message);

            }).finally(() => {
                this.isLoading(false);
            });


        }, this);

        this.eventAggregator.subscribe(AdminPageEvent.Error, (model) => {
           
            this.errorHandler.clear();
            this.successHandler.clear();
            this.errorHandler.handle(model);

        }, this);

    }

    search() {
        this.dataLoaded(false);
        this.errorHandler.clear();
        this.successHandler.clear();

        this.adminPageServiceProxy.getReturnsForOrderId(this.searchField().replace(/\s/g, "")).then((result) => {

            if (result.length <= 0)
                throw new Error('No returns found');
            this.populateReturns(result);
            this.dataLoaded(true);

        }).catch((e) => {

            this.errorHandler.handle(e.message);

        }).finally(() => {
            this.isLoading(false);
        });
    }

    populateReturns(result: AdminPageReturnModel[]) {
        this.returnViewModel.populate(result);
    }
}

export class OrderReturnsViewMode {

    readonly returnReasons: ReturnReason[];
    eventAggregator: IEventAggregator;
    returns: KnockoutObservableArray<OrderReturnModel>;

    public isShippingAlreadySelected: KnockoutComputed<boolean>;
    constructor(returnReasons: ReturnReason[], eventAggregator: IEventAggregator) {
        this.returnReasons = returnReasons;
        this.returns = ko.observableArray([]);
        this.eventAggregator = eventAggregator;

        this.isShippingAlreadySelected = ko.computed(() => {
            return  new Enumerable(this.returns()).any(p => {
                return p.isShippingIncluded() && p.status !== "Cancel";
            });
           
        });
    }

    populate(data: AdminPageReturnModel[]) {
        const dataModels = data.map(item => {
            return new OrderReturnModel(this.eventAggregator, this.isShippingAlreadySelected,this.returnReasons, item);
        });

        this.returns(dataModels);
    }
}


export class OrderReturnModel {
    readonly returnReasons: ReturnReason[];
    externalReturnId: string;
    externalOrderId: string;
    comment: KnockoutObservable<string>;
    creationDate: string;
    status: string;
    isShippingIncluded: KnockoutObservable<boolean>;
    isShippingIncludedValue: KnockoutObservable<boolean>;
    rows: KnockoutObservableArray<ReturnRowViewModel>;
    totalReturnAmount: KnockoutComputed<number>;
    isEditable: boolean;
    eventAggregator: IEventAggregator;
    isExpanded: KnockoutObservable<boolean>;
    isShippingAlreadySelected: KnockoutComputed<boolean>;

    isShippingEditable: KnockoutComputed<boolean>;
    isValidForDirectReturn: KnockoutComputed<boolean>;
    constructor(eventAggregator: IEventAggregator,
        isShippingAlreadySelected: KnockoutComputed<boolean>,
        returnReasons: ReturnReason[],
        returnModel: AdminPageReturnModel) {
        this.returnReasons = returnReasons;
        this.eventAggregator = eventAggregator;
        this.isExpanded = ko.observable(false);
        this.isEditable = returnModel.isEditable;
        this.externalReturnId = returnModel.externalReturnId;
        this.externalOrderId = returnModel.orderId;
        this.comment = ko.observable(returnModel.comment);
        this.creationDate = returnModel.creationDate;
        this.status = returnModel.status;
        this.isShippingIncluded = ko.observable(returnModel.isShippingIncluded);
        this.isShippingAlreadySelected = isShippingAlreadySelected;

        this.rows = ko.observableArray([]);
        returnModel.returnedItems.forEach(x => {
            this.rows.push(new ReturnRowViewModel(x));
        });

        this.totalReturnAmount = ko.computed(() => {
            let sum = new Enumerable(this.rows()).sum(p => {
                return p.totalPriceComputed();
            });
            return Math.round(sum * 100) / 100;
        });

        this.isValidForDirectReturn = ko.computed(() => {
            let sum= new Enumerable(this.rows()).sum(p => {
                return p.quantity();
            });
            return sum > 0;
        });
        this.isShippingEditable = ko.computed(() => {

            if (this.isEditable === false)
                return false;

            if (this.isShippingIncluded())
                return true;

            if (this.isShippingAlreadySelected() === false)
                return true;

            return false;
           
        });
    }

    toggleExpansion(context, event) {
        this.isExpanded(!this.isExpanded());
    }


    saveReturn() {
        this.eventAggregator.publish(AdminPageEvent.UpdateReturn, this.getModel(false));
    }

    directReturn() {

        if (this.isValidForDirectReturn() === false) {
            this.eventAggregator.publish(AdminPageEvent.Error, "Cannot direct return with no items");
            return;
        }
        this.eventAggregator.publish(AdminPageEvent.UpdateReturn, this.getModel(true));
    }

    cancelReturn() {
        this.eventAggregator.publish(AdminPageEvent.CancelReturn, {id: this.externalOrderId, returnId: this.externalReturnId});
    }

    getModel(isDirect: boolean) : UpdateReturnRequest {

        return {
            comment: this.comment(),
            returnId: this.externalReturnId,
            isDirectReturn: isDirect,
            isShippingIncluded: this.isShippingIncluded(),
            orderId: this.externalOrderId,
            items: new Enumerable(this.rows()).select(p => {
                return {
                    productId: p.productId,
                    quantity: p.quantity(),
                    itemPrice: p.itemPrice(),
                    lineId: p.lineId,
                    reason: p.reason()
                };
            }).toArray(),

        }; 
    }

}

export class ReturnRowViewModel {

    reason: KnockoutObservable<string>;
    productId: string;
    productName: string;
    quantity: KnockoutObservable<number>;
    maxQuantity: number;
    totalPriceComputed: KnockoutComputed<number>;
    totalPrice: KnockoutObservable<number>;
    itemPrice: KnockoutObservable<number>;
    lineId: string;
    modifyPrice: KnockoutObservable<boolean>;
    originalPrice: number;
    constructor(item: ReturnRowModel) {

        this.reason = ko.observable(item.reason);
        this.productId = item.productId;
        this.productName = item.productName;
        this.quantity = ko.observable(item.quantity);
        this.lineId = item.lineId;
   
        this.maxQuantity = item.maxQuantity;
        this.totalPrice = ko.observable(item.rowTotalPrice);
        this.itemPrice = ko.observable(item.itemPrice);
        this.modifyPrice = ko.observable(false);
        this.originalPrice = item.originalPrice;
        this.totalPriceComputed = ko.computed(() => {
           
            return Math.round(this.quantity() * this.itemPrice() * 100) / 100 ;
        });

        this.quantity.subscribe((value) => {

            if (value > this.maxQuantity) {
                this.quantity(this.maxQuantity);
            }
            if (value < 0) {
                this.quantity(0);
            }
        });

        this.itemPrice.subscribe((value) => {

            if (value > this.originalPrice) {
                this.itemPrice(this.originalPrice);
            }
            if (value <= 0) {
                this.itemPrice(this.originalPrice);
            }
               
        });
    }
}


export class OrganizationTabViewModel extends TabViewModel {

    organizationViewModel: OrganizationViewModel;
    availableRepName: KnockoutObservableArray<KeyValuePair<string, string>> = ko.observableArray([]);
    availableRep: KnockoutObservableArray<KeyValuePair<string, string>> = ko.observableArray([]);
    availableTermsOfPayment: KnockoutObservableArray<KeyValuePair<string, string>> = ko.observableArray([]);

    availableSegments: KnockoutObservableArray<KeyValuePair<string, string>> = ko.observableArray([]);
    logEntries: KnockoutObservableArray<string> = ko.observableArray([]);
    logEtriesText: KnockoutComputed<string>;

    adminPageServiceProxy: IAdminPageServiceProxy;
    errorHandler: MessageHandler;
    successHandler: MessageHandler;
    isLoading: KnockoutObservable<boolean>;
    isSapEnabled: KnockoutObservable<boolean> = ko.observable(false);
    constructor(isLoading: KnockoutObservable<boolean>, service: IAdminPageServiceProxy, errorHandler: MessageHandler,
        successHandler: MessageHandler) {
        super();
        this.errorHandler = errorHandler;
        this.successHandler = successHandler;
        this.adminPageServiceProxy = service;
        this.searchField = <IValidatableObservable<string>>ko.observable("").extend({});
        this.isLoading = isLoading;

        this.organizationViewModel = new OrganizationViewModel();

        this.logEtriesText = ko.computed(() => {

            var text = "";

            this.logEntries().forEach(x => {
                text = text + x + "\n\n";

            });

            return text;
        });
    }

    clearOrganization() {
        this.organizationViewModel.organizationId("");
        this.organizationViewModel.organizationsCreditLimitValue(0);
        this.organizationViewModel.organizationInvoiceLowerLimit(0);
        this.organizationViewModel.organizationsTermsOfPayment("");
        this.organizationViewModel.organizationsSkfInvoiceCheck(false);
        this.organizationViewModel.organizationSalesRep("");
        this.organizationViewModel.organizationSalesRepName("");
        this.organizationViewModel.organizationSegment("");
        this.organizationViewModel.organizationCostCenter("");
        this.organizationViewModel.organizationsCreditLimitValueVisible(false);
        this.organizationViewModel.organizationInvoiceLowerLimitVisible(false);
        this.organizationViewModel.organizationSalesRepVisible(false);
        this.organizationViewModel.organizationsTermsOfPaymentVisible(false);
        this.organizationViewModel.organizationSalesRepNameVisible(false);
        this.organizationViewModel.organizationSegmentVisible(false);
        this.organizationViewModel.organizationCostCenterVisible(false);
    }

    search() {
        this.dataLoaded(false);
        this.clearOrganization();
        this.errorHandler.clear();
        this.successHandler.clear();

        this.adminPageServiceProxy.searchOrganization(this.searchField()).then((result) => {

            this.populateAvaialbleSelections(result);
            this.populateOrganizationData(result);
            this.populateLogEntries(result);
            this.dataLoaded(true);

        }).catch((e) => {

            this.errorHandler.handle(e.message);

        }).finally(() => {
            this.isLoading(false);
        });
    }
    populateOrganizationData(result) {

        this.organizationViewModel.organizationId(this.searchField());
        this.organizationViewModel.organizationsCreditLimitValue(result.organizationsCreditLimitValue);
        this.organizationViewModel.organizationInvoiceLowerLimit(result.organizationInvoiceLowerLimit);
        this.organizationViewModel.organizationsTermsOfPayment(result.organizationsTermsOfPayment);
        this.organizationViewModel.organizationsSkfInvoiceCheck(result.organizationsSkfInvoiceCheck);
        this.organizationViewModel.organizationSalesRep(result.organizationSalesRep);
        this.organizationViewModel.organizationSalesRepName(result.organizationSalesRepName);
        this.organizationViewModel.organizationSegment(result.organizationSegment);
        this.organizationViewModel.organizationCostCenter(result.organizationCostCenter);
        this.organizationViewModel.organizationsCreditLimitValueVisible(result.organizationsCreditLimitValueVisible);
        this.organizationViewModel.organizationInvoiceLowerLimitVisible(result.organizationInvoiceLowerLimitVisible);
        this.organizationViewModel.organizationSalesRepVisible(result.organizationSalesRepVisible);
        this.organizationViewModel.organizationsTermsOfPaymentVisible(result.organizationsTermsOfPaymentVisible);
        this.organizationViewModel.organizationSalesRepNameVisible(result.organizationSalesRepNameVisible);
        this.organizationViewModel.organizationSegmentVisible(result.organizationSegmentVisible);
        this.organizationViewModel.organizationCostCenterVisible(result.organizationCostCenterVisible);
    }
    populateAvaialbleSelections(organizationAdminPageModel: OrganizationAdminPageModel) {

        this.availableRep([]);
        this.availableRepName([]);
        this.availableTermsOfPayment([]);
        this.availableSegments([]);
        this.isSapEnabled(organizationAdminPageModel.isSapEnabled);
        organizationAdminPageModel.salesRep.forEach(x => {
            this.availableRep.push(x);
        });

        organizationAdminPageModel.salesRepName.forEach(x => {
            this.availableRepName.push(x);
        });
        organizationAdminPageModel.termsOfPayment.forEach(x => {
            this.availableTermsOfPayment.push(x);
        });
        organizationAdminPageModel.segments.forEach(x => {
            this.availableSegments.push(x);
        });

    }

    populateLogEntries(organizationAdminPageModel: OrganizationAdminPageModel) {
        this.logEntries([]);
        organizationAdminPageModel.logEntries.forEach(x => {
            this.logEntries.push(x);
        });
    }

    updateOrganization() {


        this.errorHandler.clear();
        this.successHandler.clear();
        this.isLoading(true);
        var model = this.getModel();

        this.adminPageServiceProxy.updateOrganization(model).then((result) => {

            this.populateLogEntries(result);
            this.populateOrganizationData(result);
            // TODO use new websitestring (MyPageCompanyDetailsCreditLimitIncreaseChange) as result
            this.successHandler.handle("Ok");

        }).catch((e) => {
            this.errorHandler.handle(e);

        }).finally(() => {
            this.isLoading(false);
        });


    }

    getModel(): OrganizationRequestModel {
        return {
            organizationsTermsOfPayment: this.organizationViewModel.organizationsTermsOfPayment(),
            organizationInvoiceLowerLimit: this.organizationViewModel.organizationInvoiceLowerLimit(),
            organizationSalesRep: this.organizationViewModel.organizationSalesRep(),
            organizationSalesRepName: this.organizationViewModel.organizationSalesRepName(),
            organizationSegment: this.organizationViewModel.organizationSegment(),
            organizationCostCenter: this.organizationViewModel.organizationCostCenter(),
            organizationsCreditLimitValue: this.organizationViewModel.organizationsCreditLimitValue(),
            organizationsSkfInvoiceCheck: this.organizationViewModel.organizationsSkfInvoiceCheck(),
            organizationId: this.organizationViewModel.organizationId(),
            organizationCostCenterVisible: this.organizationViewModel.organizationCostCenterVisible(),
            organizationInvoiceLowerLimitVisible: this.organizationViewModel.organizationInvoiceLowerLimitVisible(),
            organizationSalesRepNameVisible: this.organizationViewModel.organizationSalesRepNameVisible(),
            organizationSalesRepVisible: this.organizationViewModel.organizationSalesRepVisible(),
            organizationsCreditLimitValueVisible: this.organizationViewModel.organizationsCreditLimitValueVisible(),
            organizationSegmentVisible: this.organizationViewModel.organizationSegmentVisible(),
            organizationsTermsOfPaymentVisible: this.organizationViewModel.organizationsTermsOfPaymentVisible()

        } as OrganizationRequestModel;

    }
}

export class OrganizationViewModel {

    public organizationId: KnockoutObservable<string>;
    public organizationsCreditLimitValue: KnockoutObservable<number>;
    public organizationInvoiceLowerLimit: KnockoutObservable<number>;
    public organizationsTermsOfPayment: KnockoutObservable<string>;
    public organizationsSkfInvoiceCheck: KnockoutObservable<boolean>;
    public organizationSalesRep: KnockoutObservable<string>;
    public organizationSalesRepName: KnockoutObservable<string>;
    public organizationSegment: KnockoutObservable<string>;
    public organizationCostCenter: KnockoutObservable<string>;
    public organizationsCreditLimitValueVisible: KnockoutObservable<boolean>;
    public organizationInvoiceLowerLimitVisible: KnockoutObservable<boolean>;
    public organizationSalesRepVisible: KnockoutObservable<boolean>;
    public organizationsTermsOfPaymentVisible: KnockoutObservable<boolean>;
    public organizationSalesRepNameVisible: KnockoutObservable<boolean>;
    public organizationSegmentVisible: KnockoutObservable<boolean>;
    public organizationCostCenterVisible: KnockoutObservable<boolean>;

    constructor() {
        this.organizationId = ko.observable<string>();
        this.organizationsCreditLimitValue = ko.observable<number>();
        this.organizationInvoiceLowerLimit = ko.observable<number>();
        this.organizationsTermsOfPayment = ko.observable<string>();
        this.organizationsSkfInvoiceCheck = ko.observable<boolean>();
        this.organizationSalesRep = ko.observable<string>();
        this.organizationSalesRepName = ko.observable<string>();
        this.organizationSegment = ko.observable<string>();
        this.organizationCostCenter = ko.observable<string>();
        this.organizationsCreditLimitValueVisible = ko.observable<boolean>();
        this.organizationInvoiceLowerLimitVisible = ko.observable<boolean>();
        this.organizationSalesRepVisible = ko.observable<boolean>();
        this.organizationsTermsOfPaymentVisible = ko.observable<boolean>();
        this.organizationSalesRepNameVisible = ko.observable<boolean>();
        this.organizationSegmentVisible = ko.observable<boolean>();
        this.organizationCostCenterVisible = ko.observable<boolean>();
    }
}


export interface IAdminPageServiceProxy extends IOrganizationServiceProxy, IReturnManagementServiceProxy {

}
export interface IOrganizationServiceProxy {
    searchOrganization(organizationNumber: string): Promise<OrganizationAdminPageModel>;
    updateOrganization(organizationTabModel: OrganizationTabModel): Promise<OrganizationAdminPageModel>;
}
export interface IReturnManagementServiceProxy {
    getReturnsForOrderId(orderIdNumber: string): Promise<AdminPageReturnModel[]>;
    updateOrderReturn(model: any): Promise<AdminPageReturnModel[]>;
    cancelReturn(orderId: string, returnId: string): Promise<AdminPageReturnModel[]>;
}

export class AdminPageServiceProxy implements IAdminPageServiceProxy {
    private readonly baseUrl: string = '/api/adminPage/';

    searchOrganization(organizationNumber: string): Promise<OrganizationAdminPageModel> {

        const sender = new AjaxRequestSender(this.baseUrl + "searchOrganization?id=" + organizationNumber);
        return sender.send<OrganizationAdminPageModel>("GET");

    }
    updateOrganization(organizationTabModel: OrganizationTabModel): Promise<OrganizationAdminPageModel> {
        const sender = new AjaxRequestSender(this.baseUrl + "updateOrganization");
        return sender.send<OrganizationAdminPageModel>("POST", JSON.stringify(organizationTabModel));
    }


    getReturnsForOrderId(orderId: string): Promise<any> {

        const sender = new AjaxRequestSender(this.baseUrl + "getReturnsForOrder?id=" + orderId);
        return sender.send<AdminPageReturnModel[]>("GET");
    }
    updateOrderReturn(model: UpdateReturnRequest): Promise<any> {

        const sender = new AjaxRequestSender(this.baseUrl + "UpdateReturn");
        return sender.send<OrganizationAdminPageModel>("POST", JSON.stringify(model));
    }

    cancelReturn(orderId: string, returnId: string): Promise<any> {

        const sender = new AjaxRequestSender(this.baseUrl + "cancelReturn?id=" + orderId + "&returnId="+returnId);
        return sender.send<OrganizationAdminPageModel>("DELETE");
    }

}

export class OrganizationModel {
}
