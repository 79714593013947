import * as ko from "knockout";
import { AdminPageInitialModel } from "./types/Models";
import { AdminPageViewModel, AdminPageServiceProxy } from "./models/AdminPageViewModel";
import { EventAggregator } from "./common/EventAggregator";
import { Translate, ITranslateKnockoutBindingHandlers } from "./bindings/Translate";
import { TextNotEmpty, ITextNotEmptyKnockoutBindingHandlers } from "./bindings/TextNotEmpty";
import { TranslatedPlaceholder } from "./bindings/TranslatedPlaceholder";
import { TranslationService } from "./common/TranslationService";
import { ClickedOutside, IClickedOutsideKnockoutBindingHandlers } from "./bindings/ClickedOutside";
import { ConfirmClick, IConfirmClickKnockoutBindingHandlers } from "./bindings/ConfirmClick";


declare global {
    interface Window { adminpage: any}
}

module UI.Components {
    export class AdminPageBootsrtapper {
        init(initialModel: AdminPageInitialModel, container: Element) {

            const eventAgregator = new EventAggregator();
            const translationService = new TranslationService(initialModel.translations);
            (<IConfirmClickKnockoutBindingHandlers>ko.bindingHandlers).confirmClick = new ConfirmClick();
            (<ITranslateKnockoutBindingHandlers>ko.bindingHandlers).translate = new Translate(translationService);
            (<ITextNotEmptyKnockoutBindingHandlers>ko.bindingHandlers).textNotEmpty = new TextNotEmpty();
            ko.bindingHandlers.translatedPlaceholder = <KnockoutBindingHandler<any, any, any>>new TranslatedPlaceholder();
            (<IClickedOutsideKnockoutBindingHandlers>ko.bindingHandlers).clickedOutside = new ClickedOutside();
            const service = new AdminPageServiceProxy();
      
            const viewModel = new AdminPageViewModel(initialModel, eventAgregator, service);
            ko.applyBindings(viewModel, container);
        }
    }
}

window.adminpage = window.adminpage || new UI.Components.AdminPageBootsrtapper();